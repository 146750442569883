import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import './__styles__/404.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <div styleName="container">
      <div styleName="content">
        <h3 styleName="title">We&rsquo;re a bit underwater...</h3>
        <p>
          The page you were looking for was not found. <br />{' '}
          <Link to="/" styleName="link">
            Go to Homepage
          </Link>
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
